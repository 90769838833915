<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">资源管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">课程列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">课程名称:</span>
              <el-input
                v-model="courseName"
                type="text"
                size="small"
                placeholder="请输入课程名称"
                clearable
              />
            </div>
            <span title="培训类型" class="searchboxItem ci-full flexcc">
              <span class="itemLabel">培训类型:</span>
              <tree
                ref="tree"
                @eventBtn="childBack"
                :ruleForm="ruleForm"
                size="small"
                @clearParams="clearParams"
                modal
                typeStu
              />
            </span>
            <div title="资源提供者" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">资源提供者:</span>
              <el-select
                size="small"
                v-model="compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
             <div title="销售状态" class="searchboxItem ci-full">
              <span class="itemLabel">销售状态:</span>
              <el-select
                v-model="saleState"
                placeholder="请选择销售状态"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
                width="100"
              />
              <el-table-column
                label="课程名称"
                align="left"
                prop="courseName"
                show-overflow-tooltip
                width="280"
                fixed
              />
              <el-table-column
                label="培训类型"
                align="left"
                show-overflow-tooltip
                width="150"
                prop="trainTypeNamePath"
              >
                <template slot-scope="scope">{{
                  scope.row.trainTypeNamePath || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="岗位类型"
                align="left"
                min-width="150"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.postName || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="行业类型"
                align="left"
                min-width="150"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.industryNamePath || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="职业/工种"
                align="left"
                min-width="150"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.occupationNamePath || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="培训等级"
                align="left"
                min-width="150"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.trainLevelName || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="总学时"
                align="right"
                prop="lessonNum"
                show-overflow-tooltip
              />
              <el-table-column
                label="视频总时长"
                align="right"
                prop="kpointTotalDuration"
                min-width="150"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  getTime(scope.row.kpointTotalDuration)
                }}</template>
              </el-table-column>
              <el-table-column
                label="课时标准"
                align="right"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.convertClassHour? $setDictionary("CONVERT_CLASS_HOUE", scope.row.convertClassHour) : "45分钟" }}
                </template>
              </el-table-column>
              <el-table-column
                label="总课时"
                align="right"
                show-overflow-tooltip
                minWidth="120"
              >
                <template slot-scope="scope"
                  >{{ scope.row.totalClassHours || 0 }}课时</template
                >
              </el-table-column>
              <el-table-column
                label="资源提供者"
                align="left"
                prop="compName"
                show-overflow-tooltip
                width="260"
              />
              <el-table-column
                label="销售状态"
                align="center"
                show-overflow-tooltip
                prop="salesState"
                minWidth="120"
                fixed="right"
              >
                <template slot-scope="scope">{{
                  $setDictionary("SELLSTATE", scope.row.salesState)
                }}</template>
              </el-table-column>
              <el-table-column
                label="推送单价"
                align="left"
                prop="totalPrice"
                width="80"
                show-overflow-tooltip
                fixed="right"
              />
              <el-table-column
                label="操作"
                fixed="right"
                align="center"
                width="180px"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleEdit(scope.row)"
                    >查看</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleOperation(scope.row)"
                    >查看推送日志</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>

  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "guangzhouCourseList",
  components: {
    Empty,
    PageNum,
    tree,
  },
  mixins: [List],
  data() {
    return {
      courseName: "",
      params: {},
      ruleForm: {
        Trainingtype: "",
      },
      saleState: "",
      CompanyList: [],
      compId: "",
      options: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "20",
          label: "在售",
        },
        {
          value: "10",
          label: "未售",
        },
      ],
    };
  },
  created() {
    this.getcompanytypeList();
    this.getpushstatelistList();
  },
  computed: {},
  methods: {
    /* tree */
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },

    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        key: "20",
      };
      if (this.courseName) {
        params.courseName = this.courseName;
      }
      if (this.compId) {
        params.compId = this.compId;
      }
      //去掉判断，防止为false的时候不赋值
      if (this.saleState) {
        params.salesState = this.saleState;
      }
     
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.doFetch({
        url: "/biz/canton/pushedCourse",
        params,
        pageNum,
      });
    },

    // 修改课程
    handleEdit(row) {
      let path = "/web/detailResource";
      // if (row.auditState === '10') {
      //   path = "/web/Createcourse";
      // } ----跳查看
      this.$router.push({
        path,
        query: {
          courseId: row.courseId,
          stu: "hebei",
        },
      });
    },
  
    // 资源提供者1
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 获取销售状态的下拉数据
    getcompanytypeList() {
      const sellstatelist = this.$setDictionary("SELLSTATE", "list");
      const list = [{ value: "", label: "全部" }];
      for (const key in sellstatelist) {
        list.push({
          value: key,
          label: sellstatelist[key],
        });
      }
      this.options = list;
    },
    //查看推送日志
  handleOperation(row) {
    let path = "/web/hebei/hebeiOperation";
     this.$router.push({
        path,
        query: {
          courseId: row.courseId,
        },
      });
  },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh == true) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less"></style>
